.app-title {
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  margin: 0px;
  color: #19d0ef;
}

.app-sub-title {
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: 30px;
}

.app-logo {
  width: 150px;
}

.parent-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logout-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 0;
  margin-top: 10px;
}

.logout-icon {
  color: white;
  font-size: 24px;
}

.logout-icon:hover {
  color: #19d0ef;
}

.pdf-icon {
  color: white;
  font-size: 24px;
  margin-right: 15px;
}

.pdf-icon:hover {
  color: #19d0ef;
}

.language-selection-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.language-options {
  background-color: white;
  border: none;
  color: #000000;
  padding: 5px;
  border-radius: 5px;
}

.open-all-button-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  margin-bottom: 12px;
}

.open-all-button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 15px;
}

.open-all-button:hover {
  color: #19d0ef;
}

.date-container {
  color: #c5c5c5;
  font-size: 14px;
}

@media (max-width: 1068px) {
  .app-title {
    font-size: 42px;
  }

  .app-logo {
    width: 150px;
  }

  .logout-container {
    margin-bottom: 0px;
  }

  .logout-icon {
    margin-top: 5px;
  }

  .pdf-icon {
    margin-right: 10px;
  }

  .language-selection-container {
    margin-bottom: 15px;
  }

  .language-options {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}