.expandable-chat-container {
    margin-bottom: 15px;
    border: 0px solid #ddd;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
}

.expandable-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
}

.expandable-icon {
    color: #19d0ef;
    margin-right: 10px;
}

.arrow {
    transition: transform 0.6s ease;
    color: #19d0ef;
    font-size: 22px;
    margin-right: 15px;
}

.arrow.open {
    transform: rotate(270deg);
}

.expandable-button {
    padding: 10px 20px;
    border: none;
    background-color: #000030;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.expandable-button:hover {
    background-color: #000030;
}

.tab-content {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
    max-height: 0;
    opacity: 0;
}

.tab-content.open {
    max-height: 2000000px;
    opacity: 1;
}

.chat-content {
    padding-bottom: 5px;
    padding-right: 8px;
    max-height: 700px;
    overflow-y: auto;
    background-color: #fff;
    border-bottom: 0px solid #ccc;
    display: flex;
    flex-direction: column;
}

.message-icon-container {
    display: flex;
    align-items: top;
    padding-bottom: 5px;
    padding-right: 8px;
    gap: 5px;
}

.message-icon-container.user {
    justify-content: flex-end;
    margin-bottom: 10px;
}

.message-icon-container.bot {
    justify-content: flex-start;
}

.message-icon {
    background-color: rgb(0, 21, 71);
    border-radius: 360px;
    color: white;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    margin-top: 5px;
}

.user-icon {
    background-color: rgb(0, 21, 71);
    border-radius: 360px;
    color: white;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
    margin-top: 4px;
}

.star-icon {
    background-color: transparent;
    margin-left: -5px;
    color: rgb(205, 205, 205);
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 18px;
    margin-top: 4px;
    margin-right: -3px;
}

.star-icon:hover {
    color: rgb(0, 21, 71);
    cursor: pointer;
}

.chat-tabs-header {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 15px;
}

.chat-tab-button {
    padding: 5px 20px;
    border: none;
    margin-right: 5px;
    cursor: pointer;
    border-radius: 6px;
    font-size: 13px;
    color: #000050;
    font-weight: 500;
    outline: none;
}

.chat-tab-button:hover {
    background-color: #19cfef5b;
}

.chat-tab-button.active {
    background-color: #19d0ef;
}

.event-parent-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.event-parent {
    border: 1px solid rgb(186, 186, 186);
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
}

.event-parent:hover {
    background-color: #f3f3f3;
}

.event-index {
    font-size: 18px;
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    color: #7e7e7e;
    font-weight: 600;
}

.event-title {
    font-size: 20px;
    margin: 0;
    padding: 0;
    margin-bottom: 0px;
    color: #000050;
    font-weight: 600;
}

.event-details-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.event-details-header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.event-details-title {
    font-weight: 600;
}

.event-details-header-content p {
    margin: 0;
    padding: 0;
}

.event-details-bottom {
    margin-top: 10px;
    font-size: 15px;
}

.event-details-bottom-content {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.event-details-bottom-content p {
    margin: 0;
    padding: 0;
}

.calendar-date-input {
    border: 1px solid grey;
    border-radius: 8px;
    display: block;
    width: 35%;
    box-sizing: border-box;
    outline: none;
    color: black;
    font-size: 14px;
    background-color: #f1f1f1;
    font-weight: 600;
    padding: 8px;
    margin-bottom: 25px;
    font-family: 'Work Sans', sans-serif;
}

.chat-message {
    padding: 5px 10px;
    overflow: auto;
    font-size: 16px;
    line-height: 1.5;
}

.chat-message.bot {
    background-color: transparent;
    max-width: 90%;
}

.chat-message.user {
    background-color: #19cfef95;
    padding: 6px 10px;
    border-radius: 15px;
    max-width: 60%;
}

.test {
    display: flex;
}

/* text styles */
.markdown-container > *:first-child {
    margin-top: 0;
    padding-top: 0;
}

.chat-message h1, h2, h3, h4, h5, h6 {
    color: #000050;
}

.chat-message.user p {
    margin: 0;
    padding: 0;
}

/* table styles */
.chat-message table {
    border-collapse: separate;
    border-spacing: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
    overflow: auto;
}

.chat-message th, .chat-message td {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: .35rem .50rem;
    text-align: left;
    font-size: 14px;
}

.chat-message th {
    color: #000000;
    background-color: rgba(0, 0, 0, 0.1);
    border-right: 0;
    padding: .5rem .50rem;
}

.chat-message th:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.chat-message th {
    border-bottom: none;
}

.chat-message td {
    border-bottom: 0px solid rgba(0, 0, 0, 0.15);
}

.chat-message td {
    border-right: 0px solid rgba(0, 0, 0, 0.15);
}

.chat-message td:last-child {
    border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.chat-message tr:first-child th:first-child {
    border-top-left-radius: 10px; /* Rounded top left corner */
}
.chat-message tr:first-child th:last-child {
    border-top-right-radius: 10px; /* Rounded top right corner */
}
.chat-message tr:last-child td:first-child {
    border-bottom-left-radius: 10px; /* Rounded bottom left corner */
}
.chat-message tr:last-child td:last-child {
    border-bottom-right-radius: 10px; /* Rounded bottom right corner */
}

.chat-message tr:last-child td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15); /* Adds bottom border back on all cells of the last row */
}
/*Table style ending*/

.bot-response-reaction-container {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}

.thumps-container {
    display: flex;
    gap: 14px;
}

.thumps-icon {
    color: rgb(205, 205, 205);
}

.thumps-icon:hover {
    color: rgb(0, 21, 71);
}

.word-icon {
    color: rgb(205, 205, 205);
}

.word-icon:hover {
    color: rgb(0, 21, 71);
}

.chat-input-parent {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 8px;
}

.chat-input-container {
    display: flex;
    padding: 6px;
    padding-right: 5px;
    background-color: transparent;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f8f8f8;
    width: 94%;
}

.chat-input {
    font-size: 15px;
    width: 100%;
    height: 20px;
    padding: 5px;
    border: none;
    outline: none;
    border-radius: 5px;
    background-color: transparent;
    resize: none;
    overflow: auto;
    font-family: 'Work Sans', sans-serif;
}

.clear-chat-button {
    background-color: transparent;
    border: none;
    outline: none;
}

.clear-chat-icon {
    font-size: 16px;
    color: #626262;
    cursor: pointer;
}

.send-button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #19d0ef;
}

.send-button:disabled {
    background-color: transparent;
    cursor: not-allowed;
    color: #bbbbbb;
}

.send-icon {
    font-size: 26px;
}

.chat-bot-loader {
    display: flex;
    align-items: center;
    align-self: flex-start;
    background-color: transparent;
    padding: 1px;
    border-radius: 5px;
    word-wrap: break-word;
    max-width: 60%;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.chat-loader {
    border: 4px solid rgba(243, 243, 243, 0.3);
    border-top: 4px solid #19d0ef;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1.4s linear infinite;
}

.loading-text {
    margin-top: 3px;
    margin-left: 10px;
    font-size: 14px;
    color: #686868;
    font-weight: 600;
}

.vip-item-parent-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.vip-item-parent-container p{
    margin: 0;
}

.vip-item {
    display: flex;
    gap: 10px;
}

.vip-item-content {
    border: 1px solid rgb(186, 186, 186);
    border-radius: 5px;
    width: 70%;
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
}

.vip-item-content:hover {
    background-color: #f3f3f3;
}

.vip-item-header {
    font-size: 20px;
    color: rgb(0, 21, 71);
    font-weight: 600;
    margin-bottom: 5px;
}

.delete-vip-button {
    background-color: transparent;
    border: none;
    outline: none;
}

.delete-vip-icon {
    font-size: 16px;
    color: #626262;
    cursor: pointer;
}

@media (max-width: 768px) {
    .expandables-parent {
        width: 95%;
    }

    .expandable-chat-container {
        margin-bottom: 15px;
        border: none;
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        padding: 10px;
        box-sizing: border-box;
    }

    .chat-message.bot {
        max-width: 85%;
    }

    .calendar-date-input {
        border: 1px solid rgb(113, 113, 113);
        width: 150px;
        height: 35px;
        align-content: center;
        margin-bottom: 15px;
        font-size: 13px;
    }

    .event-details-bottom {
        margin-top: 15px;
        font-size: 14px;
    }

    .event-index {
        margin-bottom: 2px;
    }

    .vip-item-content {
        border: 1px solid rgb(186, 186, 186);
        border-radius: 5px;
        width: 85%;
        padding: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
        cursor: pointer;
    }
}
